var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"global-payment"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"content-title"},[_vm._v("Collection & Payout")]),_c('div',{staticClass:"show-chart"},[_c('div',{staticClass:"charts-left"},[_c('ul',{staticClass:"ul"},[_c('li',{staticClass:"li",class:{ active: _vm.currentIndex == 1 },on:{"click":function($event){return _vm.activeIt(1)}}},[_vm._m(0),_vm._m(1),_c('i',{staticClass:"el-icon-arrow-right"})]),_c('li',{staticClass:"li",class:{ active: _vm.currentIndex == 3 },on:{"click":function($event){return _vm.activeIt(3)}}},[_vm._m(2),_vm._m(3),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('h3',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.currentDesc.title))]),(_vm.currentDesc.desc)?_c('p',{staticClass:"detail"},[_vm._v(_vm._s(_vm.currentDesc.desc))]):_vm._e(),_c('ul',{staticClass:"detail-list"},_vm._l((_vm.currentDesc.desc_list),function(item){return _c('li',{key:item.title},[_c('h3',{staticClass:"detail-list-title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"detail-list-subtitle"},[_vm._v(_vm._s(item.desc))])])}),0)]),_c('div',{staticClass:"charts-right"},[_c('div',{staticClass:"img-box",class:{ 'img-box__show': _vm.currentIndex == 1 }},[_vm._m(4)]),_c('div',{staticClass:"img-box",class:{ 'img-box__show': _vm.currentIndex == 3 }},[_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/global-payment/shopping-cart.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"describe"},[_c('h3',{staticClass:"title"},[_vm._v("Collections")]),_c('h4',{staticClass:"subtitle"},[_vm._v("Global Collection Account")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/global-payment/money.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"describe"},[_c('h3',{staticClass:"title"},[_vm._v("Payouts")]),_c('h4',{staticClass:"subtitle"},[_vm._v("Global Payouts")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rise-up"},[_c('img',{attrs:{"src":require("@/assets/global-payment/demo22.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rise-up"},[_c('img',{attrs:{"src":require("@/assets/global-payment/demo3.png")}})])
}]

export { render, staticRenderFns }