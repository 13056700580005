
export const enList = [
  {
    title: `Support for multiple currencies`,
    desc: `Covering 71 countries and regions worldwide, 50+ currencies.`
  },
  {
    title: 'Higher Efficiency',
    desc: `Sign up an account in minutes with 0 fee; Collection+Payout can be completed in 1 business day.`
  },
  {
    title: `Better Security`,
    desc: 'Bank level security is guaranteed by our powerful payment network and financial institution partership.'
  },
  {
    title: 'Lower Cost',
    desc: `Lower payment cost, higher transparency on FX coversion Fee - lower than average.`
  }
]

export const zhList = [
  {
    title: '支持多币种',
    desc: `覆盖全球71个国家及地区，
    266种支付方式，50+币种。
    
    `
  },
  {
    title: `接入简单，快速上线
    `,
    desc: `提供API/SDK/插件等多种对接方式，保证接口高可用性和稳定性。

    `
  },
  {
    title: `提供反欺诈服务
    `,
    desc: `通过风险策略、数据模型，实施全方面、
    多角度的实时交易监控，进行欺诈保护。
    `
  },
  {
    title: `以客户为中心
    `,
    desc: `为每个客户配备专业的服务团队，
    分析交易数据，寻找客户的增长点。`
  }
]
