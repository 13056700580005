var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"global-account"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"content-title"},[_vm._v("Multi-currency Wallet")]),_c('div',{staticClass:"show-chart"},[_c('div',{staticClass:"charts-left charts-left-white"},[_c('ul',[_c('li',{class:{ active: _vm.currentIndex == 0 },on:{"click":function($event){return _vm.activeIt(0)}}},[_vm._m(0),_vm._m(1),_c('i',{staticClass:"el-icon-arrow-right el-icon-arrow-right-white"})]),_c('li',{class:{ active: _vm.currentIndex == 1 },on:{"click":function($event){return _vm.activeIt(1)}}},[_vm._m(2),_vm._m(3),_c('i',{staticClass:"el-icon-arrow-right el-icon-arrow-right-white"})])]),_c('h3',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.currentDesc.title))]),_c('p',{staticClass:"detail"},[_vm._v(" "+_vm._s(_vm.currentDesc.desc)+" ")])]),_c('div',{staticClass:"charts-right"},[_c('div',{staticClass:"img-box",class:{ 'img-box__show': _vm.currentIndex == 0 }},[_vm._m(4)]),_c('div',{staticClass:"img-box",class:{ 'img-box__show': _vm.currentIndex == 1 }},[_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/global-account/wallet.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"describe"},[_c('h3',{staticClass:"title"},[_vm._v("Wallet")]),_c('h4',{staticClass:"subtitle"},[_vm._v("Multi-currency Wallet")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/global-account/exchange-rate.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"describe"},[_c('h3',{staticClass:"title"},[_vm._v("FX")]),_c('h4',{staticClass:"subtitle"},[_vm._v("FX Management")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rise-up"},[_c('img',{attrs:{"src":require("@/assets/global-account/demo1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rise-up"},[_c('img',{attrs:{"src":require("@/assets/global-account/demo2.png")}})])
}]

export { render, staticRenderFns }